.about-banner-section {
  background-color: var(--blue);
  background-repeat: no-repeat;
  background-position: top right;
  display: flex;
  font-weight: bold;
  padding-bottom: 0;
  margin: -1rem -1rem 0 -1rem;
}

.about-banner-headline {
  font-family: 'Montserrat';
  align-self: flex-end;
  font-weight: normal;

  h1 {
    line-height: 80px;
  }

  strong {
    font-weight: bold;
  }
}

.about-banner-description {
  font-family: Roboto, 'Noto Sans', sans-serif;
  font-size: 24px;
  line-height: 38px;
}

.about-privacy-icons {
  gap: 2rem 1rem;
  margin-bottom: 2rem;
}
.about-privacy-icon {
  text-align: center;
  width: 120px;
}

.about-phones {
  color: var(--dark-grey);
}
.about-phones img {
  max-width: 100%;
}

@media screen {
  .about-banner-section {
    position: relative;
    background-image: url(/static/images/doctor.svg);
    background-size: 100%;
    height: 500px;
  }
  .about-banner-section::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: " ";
    //background: linear-gradient(180deg, rgba(0, 61, 167, 0) 25%, #003DA7 100%);
    z-index: 1;
  }
  .about-banner-headline {
    z-index: 2;
  }

  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .about-banner-section {
      background-image: url(/static/images/doctor.svg);
    }
  }
  @media (min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
    .about-banner-section {
      background-image: url(/static/images/doctor.svg);
    }
  }
}

@media screen and (min-width: 800px) {
  .about-banner-section {
    background-image: url(/static/images/doctor.svg);
    background-size: 100% 80%;
    background-position: center right;
    height: 834px;
    margin-top: 100px;
    margin-right: -50%;
  }

  .about-banner-headline {
    max-width: 25%;
    align-self: center;
    margin-left: 5%;
  }

  @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
    .about-banner-section {
      background-image: url(/static/images/doctor.svg);
    }
  }
  @media (min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
    .about-banner-section {
      background-image: url(/static/images/doctor.svg);
    }
  }

  .about-phones-list {
    justify-content: space-evenly;
  }
  .about-phones-list > * {
    width: 386px;
  }
}
