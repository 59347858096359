@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Light.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Light.woff') format('woff');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Regular.woff') format('woff');
}
@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Italic.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat Medium';
  font-style: italic;
  font-weight: normal;
  src: url('/static/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Medium.woff') format('woff');
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat Black';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/Montserrat/Montserrat-Black.woff2') format('woff2'),
       url('/static/fonts/Montserrat/Montserrat-Black.woff') format('woff');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/NotoSans/NotoSans-Regular.woff2') format('woff2'),
       url('/static/fonts/NotoSans/NotoSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: normal;
  src: url('/static/fonts/NotoSans/NotoSans-Italic.woff2') format('woff2'),
       url('/static/fonts/NotoSans/NotoSans-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/NotoSans/NotoSans-Bold.woff2') format('woff2'),
       url('/static/fonts/NotoSans/NotoSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: bold;
  src: url('/static/fonts/NotoSans/NotoSans-BoldItalic.woff2') format('woff2'),
       url('/static/fonts/NotoSans/NotoSans-BoldItalic.woff') format('woff');
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  src: url('/static/fonts/Roboto/roboto-v29-latin-regular.woff2') format('woff2'),
       url('/static/fonts/Roboto/roboto-v29-latin-regular.woff') format('woff'); 
}

/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: normal;
  src: url('/static/fonts/Roboto/roboto-v29-latin-italic.woff2') format('woff2'),
       url('/static/fonts/Roboto/roboto-v29-latin-italic.woff') format('woff'); 
}

/* roboto-bold - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: bold;
  src: url('/static/fonts/Roboto/roboto-v29-latin-700.woff2') format('woff2'),
       url('/static/fonts/Roboto/roboto-v29-latin-700.woff') format('woff'); 
}
