@import "fonts";
@import "pages/about";

:root {
  --white: #FFF;
  --blue: rgb(0,61,167);
  --blue-50: rgba(0,61,167,0.5);
  --blue-25: rgba(0,61,167,0.25);
  --light-blue: #F2F5FC;
  --grey: #999;
  --light-grey: #CCC;
  --dark-grey: #333;
  --orange:rgb(255, 132, 0);
  --orange-50:rgba(255, 132, 0, 0.5);
  --orange-25:rgba(255, 132, 0, 0.25);
  --dim-10: rgba(0, 0, 0, 0.1);
  --dim-05: rgba(0, 0, 0, 0.05);
  --percent: 0;
  --mobile-scroll-margin-top: 50px;
  --shadow-small: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

body {
  font-family: Roboto, 'Noto Sans', sans-serif;
  font-size: 14px;
  line-height: 1.3;
  padding: 0;
  margin: 0;
}

*, :after, :before {
  box-sizing: border-box;
}

a {
  color: var(--blue);
  cursor: pointer; // even without href attribute
}

figure {
  margin: 0;
}

h1 {
  font-size: 36px;
  font-weight: normal;
  color: var(--blue);
}

h2 {
  font-size: 24px;
  font-weight: normal;
  color: var(--blue);
}

h3 {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Montserrat Light', 'Noto Sans', sans-serif;
  color: var(--dark-grey);
}

h4 {
  font-size: 1rem;
  font-weight: bold;
}

select {
  border: 2px solid var(--dim-10);
  border-radius: 99px;
  background: white;
  padding: 5px;
  color: var(--grey);
  font: normal 14px 'Montserrat Medium', 'Noto Sans', sans-serif;
}

// HELPERS

.text-center {
  text-align: center;
}

.inline-list {
  list-style: none;
  padding-left: 0;
}
.inline-list > * {
  display: inline-block;
}

.inline-flex {
  display: flex;
  flex-flow: wrap;
  justify-content: space-evenly;
  list-style: none;
  padding-left: 0;
}
.inline-flex > * {
  flex: 1fr;
  text-align: center;
}


// MENU: top (mobile) or sidebar (desktop)
.menu{
  background-color: var(--light-blue);

}
.menu a {
  color: var(--blue);
  text-decoration: none;
  font-weight: normal;
}
.menu-nav {
  display: none;
  margin-right: 20px;
  margin-left: 20px;
}
.menu-nav.is-visible {
  display: block;
}
.menu-links {
  margin: 0;
  padding: 1rem;
  list-style: none;
  color: var(--blue);
}
.menu-link {
  padding: 0.5rem 0;
}
.menu-link ul {
  padding: 0.5rem 1rem 0;
}

.menu-link.active a{
  font-weight: bold;
}

.menu-sublink {
  padding: 0.5rem 0;
  list-style: none;
  padding-left: 18px;
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  a {
    color:  var(--blue);
    text-decoration: none;
  }
  &.active a{
    font-weight: bold;
  }
  // Dashed line
  &:not(:last-child) a::before {
    content: '';
    position: absolute;
    height: 100%;
    left: 5px;
    top: 50%;
    border-left: 2px var(--blue) dotted;
  }
  // Solid line
  &:not(:last-child) a::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 50%;
    height: 0%;
    border-left: 2px  var(--blue) solid;
  }
  &.active a::after {
    height: var(--percent);
  }
  &.done a::after {
    height: 100%;
  }
  // Solid dot
  &::before {
    transition: all 0.25s;
    position: absolute;
    top: calc(50% - 3px);
    left: 3px;
    display: inline-flex;
    margin-right: 12px;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: white;
    border: 3px  var(--blue) solid;
    z-index: 1;
  }
  // Outline dot
  &.active::before {
    top: calc(50% - 6px);
    left: 0;
    width: 12px;
    height: 12px;
    border-width: 2px;
    z-index: 2;
  }
}

// CONTENTS
.page-section {
  padding: 1rem;
}

.page-section.is-inverted {
  background-color: var(--white);
  color: var(--blue);
}

.full-width{
  display: block; // avoid the vertical-align:baseline of inline elements
  width: 100%;
}

.scorecards {
  display: flex;
  flex-direction: horizontal;
  gap: 10px;
  padding-bottom: 20px;
}

.scorecard {
  flex: 1;
  width: 100%;
  border-radius: 4px;
  margin-top: 5%;
  padding-right: 5%;
}

.scorecard.active {
  background-color: var(--blue-25);
}

section {
  margin-left: 5%;
  margin-right: 5%;
}

// MOBILE SPECIFIC DESIGN
@media screen and (max-width: 799px) {
  .menu {
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 3;
    box-shadow: 0   0 4px 0 rgba(0, 0, 0, 0.1),
                0 1px 2px 0 rgba(0, 0, 0, 0.2),
                0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }
  .menu-bar {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 12px;
    margin-right: 60px;
    margin-left: 60px;
  
  }
  .menu-logo {
    flex: 1;
  }

  section {
    scroll-margin-top: var(--mobile-scroll-margin-top);
  }

  .scorecards{
    display: block;
  }

  .about-banner-headline {
    h1{
      font-size: 30px;
      line-height: normal;
      margin-left: 20px;
    }

    p {
      margin-left: 20px;
      margin-right: 10px;
      font-size: 20px;
    }
  }

  .about-banner-section {
    background-size: 55%;
  }
  
  .donuts{
    display: block;
  }
}

// DESKTOP SPECIFIC DESIGN
@media screen and (min-width: 800px) {
  body {
    font-size: 22px;
  }
  h1 {
    font-family: 'Montserrat';
    font-size: 80px;
  }
  h2 {
    font-family: 'Montserrat';
    font-size: 44px;
    font-weight: bold;
  }

  .columns {
    display: flex;
    flex-direction: row;
  }

  .page {
    display: grid;
    grid-template-columns: 350px 1fr;
    max-height: 100vh;
    overflow: hidden;
  }
  .page-content {
    max-height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .menu-bar {
    padding: 1rem;
  }
  .menu-toggle {
    display: none;
  }
  .menu-nav {
    display: block;
  }
  .menu-links {
    padding-top: 0;
  }
}
